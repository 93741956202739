<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50"><h5>Filter Location</h5></b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12">
                        <location-filters
                            size="sm"
                            align="horizontal"
                            @filter="changeFilters"
                        />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                            <b-button
                                variant="primary"
                                size="sm"
                                @click="invokeCreateForm()"
                            >
                                <span class="text-nowrap">Assign Station</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(name)="data">
                    <b-link :to="{ path: 'stations/' + data.item.id, params: { id: data.item.id, category: data.item.type } }" style="cursor:pointer;color:#888;">
                        <span v-if="data.item.type == 0">HEAD OFFICE</span>
                        <span v-else>{{ data.item.station.name.toUpperCase() }}</span>
                    </b-link>
                </template>

                <template #cell(location)="data">
                    <b-link :to="{ path: 'stations/' + data.item.id, params: { id: data.item.id, category: data.item.type } }">
                        <span v-if="data.item.type == 1">
                            <b-badge variant="light-warning">
                                {{ data.item.location.level.name }}
                            </b-badge>
                        </span>
                        <span v-else-if="data.item.type == 2">
                            <b-badge variant="light-info">Market Center</b-badge>
                        </span>
                        <span v-else-if="data.item.type == 3">
                            <b-badge variant="light-success">Storage Warehouse</b-badge>
                        </span>
                        <span v-else>
                            <b-badge variant="light-danger">National</b-badge>
                        </span>
                    </b-link>
                </template>

                <!-- Column: Status -->
                <template #cell(isActive)="data">
                    <b-link :to="{ path: 'stations/' + data.item.id, params: { id: data.item.id, category: data.item.type } }">
                        <b-badge
                            pill
                            :variant="`light-${resolveStatusVariant(data.item.isActive)}`"
                            class="text-capitalize"
                        >
                            <span v-if="data.item.isActive">In Use</span>
                            <span v-else>Blocked</span>
                        </b-badge>
                    </b-link>
                </template>

                <template #cell(count)="data">
                    {{ data.item.users.length.toLocaleString() }}
                </template>

                <template #cell(users)="data">
                    <b-avatar-group size="32px">
                        <b-avatar
                            v-for="user in data.item.users"
                            :key="user.id"
                            v-b-tooltip.hover
                            class="pull-up"
                            :title="user.person.middleName != null? user.person.firstName + ' ' + user.person.middleName.substring(0, 1) + ' ' + user.person.lastName : user.person.firstName + ' ' + user.person.lastName"
                            :src="require('@/assets/images/portrait/small/avatar-s-10.jpg')"
                        />
                    </b-avatar-group>
                </template>
                
                <template #cell(allocate)="data">
                    <b-button
                        :to="{ path: 'stations/' + data.item.id, params: { id: data.item.id, category: data.item.type } }"
                        title="Re-allocate"
                        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon"
                    >
                        <feather-icon icon="CornerUpRightIcon" />
                    </b-button>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BMedia, BAvatar, BAvatarGroup, BLink, BImg, BBadge,
    VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import LocationFilters from '@core/components/cromis/filters/LocationFilters'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import workStationStoreModule from '@/views/cromis/station/workStationStoreModule'
import useWorkStationList from '@/views/cromis/station/useWorkStationList'
import moment from 'moment'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BMedia, BAvatar, BAvatarGroup, BLink, BImg, BBadge,

        LocationFilters,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    setup(props, context) {
        const CROMIS_STORE_MODULE_NAME = 'cromis-station'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, workStationStoreModule)

        // Unregister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
        })

        const changeFilters = async (location) => {
            location_id.value = location != null ? location.id : null
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            location_id,
            resolveStatusVariant,
        } = useWorkStationList()

        return {
            // Data

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Methods
            changeFilters,
            location_id,
            resolveStatusVariant,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';
</style>