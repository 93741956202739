import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAgreementsList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'name', label: 'Station', sortable: true },
        { key: 'location', label: 'Level', sortable: true },
        { key: 'isActive', label: 'Status', sortable: false },
        { key: 'count', label: 'Users', sortable: false },
        { key: 'users', label: 'User List', sortable: false },
        'allocate',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(false)
    const location_id = ref(null)

    const refetch = () => {
        records.value.refresh()
    }

    watch([location_id, currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store.dispatch('cromis-station/list', {
            location_id: location_id.value,
            query: searchQuery.value,
            per_page: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { stations, meta } = response.data

            meta.value = meta

            callback(stations)
            totalRecords.value = meta.value.total
            from.value = meta.value.from
            to.value = meta.value.to
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching working stations',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveGrowerTypeVariant = society => {
        if (society !== null) return 'success'
        return 'primary'
    }

    const resolveStatusVariant = status => {
        if (status) return 'success'
        return 'warning'
    }

    return {
        fetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
    
        // resolveUserRoleVariant,
        // resolveUserRoleIcon,
        resolveStatusVariant,
        refetch,
    
        // Extra Filters
        location_id,
    }
}
